function designElements () {
	var elements = document.querySelectorAll('[data-design-elem]');
	Array.prototype.forEach.call(elements, function(el, i){
		var elem = '[data-design-elem-box="' + el.dataset.designElem + '"]';
		var designElement = document.querySelector('.design-element' + elem);
		var height = parseFloat(getComputedStyle(el, null).height.replace('px', '')) + 48;

		designElement.style.height = height + 'px';
	});
}

window.addEventListener('resize', function () {
	designElements();
});

document.addEventListener('lazyloaded', function (event) {
	designElements();
});
